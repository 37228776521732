import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import HeroSection from "../components/Home/HeroSection";
import BlockAboutOne from "../components/About/BlockAboutOne";
import Values from "../components/About/Values";
import Directories from "../components/Home/Directories";
import BlockHomeOne from "../components/Home/BlockHomeOne";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import { Link } from "react-router-dom";
import { ButtonContent } from "../components/global/boton/ButtonContent";
import VideoPromocional from "../components/global/VideoPromocional";

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <Modal />
        <HeroSection />
        <div className="mt-[-110px]">
          <Values />
        </div>
        <BlockHomeOne />
        <Directories />

        <CounterUp />
        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
            />
            : null
        }
        <BlockAboutOne />
        <div className="w-4/5 mx-auto  pb-10">
          <div className="flex flex-col  items-center md:justify-between md:items-center pb-8">
            <h3>Our Services</h3>
            <ButtonContent btnStyle='three' btnName='view all services' />
          </div>
          <div className='grid md:grid-cols-3 gap-5'>
            {
              rpdata?.dbServices?.slice(0, 6).map((serv, index) => {
                return (
                  <div key={index} className='p-5 border-[#E9E9E9] border-[1px] drop-shadow-lg'>
                    <img
                      src={serv.description[0].img}
                      alt="No fount"
                      className="w-full h-[250px] md:h-[400px] object-cover"
                    />
                    <Link to='/services'>
                      <h5 className="py-3 text-center">{serv.name}</h5>
                    </Link>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="bg-[#001a63] py-[100px]">
          <FormHome />
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
